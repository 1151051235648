/*
 * Copyright 2020 by Avid Technology, Inc.
 */

export default (entitlementsList, entitlements, embeddedInMode) => {
    if (embeddedInMode && entitlements.length && !entitlementsList.length) {
        return true;
    }
    if (!embeddedInMode || !entitlementsList.length || !entitlements.length) {
        return false;
    }
    return !entitlements.every(entitlement => entitlementsList.includes(entitlement));
};
