/*
 * Copyright 2018 by Avid Technology, Inc.
 */

export default (iamToken) => {
    if (!window.AV) {
        window.AV = {};
    }
    window.AV.internal = window.AV.internal || {};
    window.AV.internal.masterRegion = iamToken && iamToken.masterRegion;
    window.AV.internal.user = {};
    Object.defineProperties(window.AV.internal.user, {
        contextId: {
            writable: false,
            value: iamToken && iamToken.contextId
        },
        identityId: {
            writable: false,
            value: iamToken && iamToken.identityId
        }
    });
};
