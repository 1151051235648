/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { ADMIN_CONTEXT_ID, AUTHORIZATIONS_PATH } from '../../util/config';

export default () => {
    const data = {
        entity: {
            kind: 'role',
            identityId: AV.internal.user.identityId,
            contextId: ADMIN_CONTEXT_ID,
            roleId: '00000000-0000-4000-a000-000000000001'
        }
    };

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return fetch(AUTHORIZATIONS_PATH, {
        method: 'post',
        credentials: 'same-origin',
        headers,
        body: JSON.stringify(data)
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return Promise.reject();
    }).then(({ entity }) => {
        if (!entity) {
            return Promise.reject();
        }
        return entity.authorized && AV.internal.user.contextId === ADMIN_CONTEXT_ID;
    });
};
