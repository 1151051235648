/*
 * Copyright 2018, 2020 by Avid Technology, Inc.
 */

export default () => {
    return fetch('/configuration/toggles-config.json', {
        headers: {
            Accept: 'application/json'
        },
        credentials: 'same-origin'
    }).then((response) => {
        if (!response.ok) {
            throw new Error('Configuration toggles are not available');
        }
        return response.json();
    }).catch((err) => {
        console.warn('[Configuration toggles] Retrieving error', err);
        throw new Error('Configuration toggles are not available');
    });
};
