/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { getAuthToken } from './get-auth-token';
import saveIamTokenData from './saveIamTokenData';

export default () => {
    return getAuthToken().then(({ iamToken }) => {
        return saveIamTokenData(iamToken);
    });
};
