/*
 * Copyright 2018 by Avid Technology, Inc.
 */

export default () => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/core/css/fatal-error.css';
    document.head.appendChild(link);
};
