/*
 * Copyright 2018, 2020 by Avid Technology, Inc.
 */

import { AUTH_PATH, AUTH_PATH_EXTEND } from '../../util/config';

export const CONNECTION_TEST_TIMEOUT = 1000 * 5;
export const RETRY_COUNTS = 5;
const SESSION_DEAD_CODE = 401;

const delay = (ms = CONNECTION_TEST_TIMEOUT) => new Promise(resolve => setTimeout(resolve, ms));

const onSuccess = (response) => {
    if ((response.status >= 200 && response.status < 300) || response.ok) {
        return Promise.resolve(response.json());
    }
    return Promise.reject(response);
};

export const getAuthToken = () => {
    return fetch(AUTH_PATH, {
        method: 'get',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
        },
    })
        .then(onSuccess);
};

export const extendAccessToken = () => {
    return fetch(AUTH_PATH_EXTEND, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(onSuccess);
};

export const extendTokenWithRetry = (ok, fail, times) => extendAccessToken()
    .then(ok)
    .catch((error) => {
        if (!times || error.status === SESSION_DEAD_CODE) {
            fail(error);
        } else {
            console.warn(`[Auth] Recreate token retry (${RETRY_COUNTS - times + 1}/${RETRY_COUNTS}) in ${CONNECTION_TEST_TIMEOUT} ms..`);
            delay()
                .then(() => extendTokenWithRetry(ok, fail, times - 1));
        }
    });
