/*
 * Copyright 2018 by Avid Technology, Inc.
 */

const ACCESS_DENIED = 403;

export default () => {
    return fetch('/apis/avid.iam;version=3;realm=global/principals/self', {
        method: 'get',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === ACCESS_DENIED) {
                console.error('Access denied request user principals');
                return Promise.reject(new Error('accessDenied'));
            }
            return Promise.reject();
        });
};
