/*
 * Copyright 2018 by Avid Technology, Inc.
 */

export default (isAdmin) => {
    return new Promise((resolve) => {
        const path = window.location.pathname || '/';

        if (isAdmin && path === '/') {
            window.history.pushState(window.history.state || null, window.document.title, `/admin${window.location.hash}`);
        } else if ((isAdmin && !path.includes('/admin')) || (!isAdmin && path.includes('/admin'))) {
            return AV.LoginProvider.logout(path).then(() => {
                resolve(false);
            });
        }
        return resolve(true);
    });
};
