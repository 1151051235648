/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { getAuthToken } from './get-auth-token';

const UNAUTHORIZED = 401;
const ACCESS_DENIED = 403;

const loggedCheck = () => {
    return getAuthToken()
        .then(({ iamToken }) => {
            return {
                isValid: true,
                iamToken,
            };
        }).catch((error) => {
            if (error.status === UNAUTHORIZED) {
                return {
                    isValid: false,
                };
            }
            if (error.status === ACCESS_DENIED) {
                console.error('Access denied request iam Token');
                return Promise.reject(new Error('accessDenied'));
            }
            throw new Error('Request error');
        });
};

export default loggedCheck;
